.footer_contact_form {
	padding-top: 0.813em;
	margin-bottom: 2em;
	position: relative;
	&:before {
		content: "";
		font-size: 1em;
		position: absolute;
		left: -3.125em;
		top: -12.500em;
		width: 16.000em;
		height: 16.000em;
		background: #f68616 0%;
		background: -webkit-gradient(linear, left top, right top, from(#f68616), to(#ee2a1e));
		background: linear-gradient(90deg, #f68616 0%, #ee2a1e 100%);
		-webkit-filter: blur(1.188em);
		filter: blur(1.188em);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-webkit-box-shadow: 1.250em 1.250em 0 0.625em #000000;
		box-shadow: 1.250em 1.250em 0 0.625em #000000;
		display: none;
	}
	p {
		font-size: 1.125em;
		color: #ffffff;
		margin-bottom: 2.556em;
	}
	.form_error {
		color: red;
		font-weight: 600;
	}
	form{
		.email input,
		.name input,
		.message textarea {
			color: #a4a4a4;
			&:focus-visible {
				outline: none;
			}
			&:-internal-autofill-selected,
			&:-internal-autofill-selected:hover,
			&:-internal-autofill-selected:active,
			&:-internal-autofill-selected:focus,
			&:-internal-autofill-selected:focus-visible {
				transition-delay: 9999s;
				transition-property: background-color, color;
			}
			&:not(:placeholder-shown) + label {
				background: #052a33;
				color: #a4a4a4;
				padding: 0 1em;
			}
			&:focus {
				background: transparent;
				&:not([readonly]) {
					border: 0.063em solid #cfcfcf;
				}
				&+ label {
					background: #052a33;
					color: #a4a4a4;
					padding: 0 1em;
				}
			}
	}
	.recaptcha { transform: scale(0.8) translate(-30px, -10px); height: 62px; }
	.submit { font-size: 1.475em;
		padding: 0.5em 0em; }
	}
}
@media (min-width: 992px) {
	.footer_contact_form {
		&:before {
			display: block;
		}
	}
}
