@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_utilities.scss';
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
@import '../styles/_gradient';
$default-gradian-deg: 90deg;
$default-gradian-colors: #f68616 0%, #ee2a1e 100%;

.footer {
    background: #05272f;
    background: -webkit-gradient(
        left top,
        right bottom,
        color-stop(0%, #05272f),
        color-stop(50%, #002b34),
        color-stop(100%, #031820)
    );
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#05272f),
        color-stop(50%, #002b34),
        to(#031820)
    );
    background: linear-gradient(180deg, #05272f 0%, #002b34 50%, #031820 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05272f', endColorstr='#031820', GradientType=1 );
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-top: 4em;
    max-width: 5752px;
    margin: 0 auto;
    margin-top: -1em;
    :global(.fixed_body) & {
        display: none;
    }
    .to_top {
        background: url('/static/img/to-up-background.svg');
        background-size: 100% 100%;
        width: 11.188em;
        height: 3.938em;
        display: block;
        margin: 0 auto;
        text-align: center;
        padding-top: 0.75em;
        margin-bottom: 4.25em;
        color: #00264f;
        text-decoration: none;
        background-color: transparent;
        border: 0;
        margin-top: -4em;
        &:focus {
            outline: none;
        }
        &:hover {
            color: #000103;
            text-decoration: underline;
        }

        .icon_caret_up {
            width: auto;
            height: 35%;
        }
    }
    .container {
        z-index: 3;
        position: relative;
        &.renderTopButton::before {
            content: '';
            position: absolute;
            right: 8.375em;
            top: 3.313em;
            width: 5em;
            height: 5em;
            background: #04faed;
            -webkit-filter: blur(0.625em);
            filter: blur(0.625em);
            -webkit-transform: rotate(44.9deg);
            transform: rotate(44.9deg);
            font-size: 1em;
            display: none;
        }
        .footer_text {
            color: #ffffff;
        }

        .footer_text h4 {
            text-align: center;
            font-size: 3em;
            font-weight: 600;
            margin-bottom: 0.375em;
        }

        .footer_text p {
            text-align: center;
            display: block;
            font-weight: 300;
            font-size: 1.125em;
        }

        .footer_text .btn {
            margin-bottom: 2em;
            margin-top: 1.357em;
            font-size: 1.475em;
            padding: 0.5em 0em;
        }
        .footer_animation {
            position: absolute;
            right: -37em;
            position: absolute;
            top: 12.875em;
            display: none;
        }
    }
    .links {
        margin-bottom: 3.719em;
        padding-top: 7em;
        z-index: 2;
        position: relative;
        > div {
            ul {
                margin: 0;
                padding: 0;
            }
            li {
                margin: 0;
                padding: 0;
                margin-bottom: 1.5em;
                list-style: none;
                a {
                    font-size: 1em;
                    font-weight: 400;
                    opacity: 0.63;
                    color: #ffffff;
                }
            }
            span {
                font-size: 1.25em;
                font-weight: 500;
                margin-bottom: 1.65em;
                color: #ffffff;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                @media (max-width: 767.98px) {
                    cursor: pointer;
                }
            }
        }
    }
    .copyright {
        border-top: 0.063em solid #d8d8d8;
        padding-top: 3.156em;
        padding-bottom: 2.438em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .brand {
            font-family: 'Roboto';
            font-size: 2em;
            color: #ffffff;
            flex: 1;
            display: flex;
            align-items: center;
            @include media-breakpoint-up(md) {
                flex: 0 1 14em;
            }
            @include media-breakpoint-up(lg) {
                flex: 0 1 14em;
            }
            img {
                width: 100%;
                height: auto;
            }
            span {
                font-weight: 700;
                color: #04faed;
            }
            &:hover {
                color: #ffffff;
            }
        }
        .social {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: flex-end;
            a {
                display: flex;
                height: 2.5em;
                width: 2.5em;
                border-radius: 0.813em;
                background: #ffffff;
                margin-right: 1em;
                -webkit-transform: rotate(44.9deg);
                transform: rotate(44.9deg);
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                > img {
                    -webkit-transform: rotate(-44.9deg);
                    transform: rotate(-44.9deg);
                }
            }
        }
    }
}
@media (max-width: 767.98px) {
    .footer {
        overflow: hidden;
        > .container {
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
        .copyright {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            padding-top: 1.5em;
            padding-bottom: 1.5em;
            .brand {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
                img {
                    // max-width: 44px;
                    height: auto;
                }
            }
            .social {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
                margin-bottom: 2em;
                a {
                    font-size: 1.125em;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .footer {
        .container {
            &.renderTopButton::before {
                display: block;
            }
            .footer_animation {
                display: block;
            }
        }
        .links span svg {
            display: none;
        }
    }
}
@media (max-width: 991.98px) {
    .footer {
        .links {
            margin-bottom: 1.5em;
            > div.active {
                ul {
                    display: block;
                }
            }
            > div {
                ul {
                    display: none;
                }
                span {
                    svg{
                        margin-left: auto;
    height: auto;
    width: 7%;
                    }
                }
            }
        }
    }
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    &:hover {
        color: #000;
        text-decoration: none;
    }
}
.icon_close {
    path {
        fill: #ffffff;
    }
}
.icon_link {
    margin-left: 15px;
}
.coockie_section {
    background-color: #031921;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    color: #ffffff;
    padding: 1.5em 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    :global(.fixed_body) & {
        display: none;
    }
    .coockies_inner {
        position: relative;
        text-align: center;
        font-weight: 300;
        max-width: 1440px;
        > a {
            &:not(.close) {
                font-weight: 700;
                background: -webkit-gradient(
                    linear,
                    left top,
                    right top,
                    from(#f68616),
                    to(#ee2a1e)
                );
                background: linear-gradient(90deg, #f68616 0%, #ee2a1e 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                i {
                    margin-left: 2.071em;
                    vertical-align: middle;
                }
            }
        }
        a {
            color: #ffffff;
            font-size: 0.875em;
        }
        .close {
            position: absolute;
            right: 0.5em;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}
@media (max-width: 767.98px) {
    .coockie_section {
        .coockies_inner {
            padding-right: 2.5em;
            .close {
                right: 1.143em;
            }
        }
    }
}
@media (min-width: 768px) {
    .coockie_section {
        .coockies_inner {
            > a {
                &:not(.close) {
                    margin-left: 3.857em;
                }
            }
        }
    }
}
